//微信分享
import wx from "weixin-js-sdk";
import request from "./request";


const wxShare = async () => {
    let url = window.location.href
    const res = await request.get('/apis/wx/config?url=' + url)
    let config = res.data
    wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: config.appId, // 必填，公众号的唯一标识
        timestamp: config.timestamp, // 必填，生成签名的时间戳
        nonceStr: config.nonceStr, // 必填，生成签名的随机串
        signature: config.signature,
        jsApiList: [
            'updateTimelineShareData', 'updateAppMessageShareData'
        ]
    })

    wx.ready(() => {
        wx.checkJsApi({
            jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
            success: function (res) {
                // 以键值对的形式返回，可用的api值true，不可用为false
                // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
                console.log(res, 'checkJsApi')

                let shareData = {
                    title: '康贝测（KB-Tseq）', // 分享标题
                    desc: '', // 分享描述
                    link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: 'https://kindstarbiotech.oss-cn-hangzhou.aliyuncs.com/rosefinch/share/beitailogo.png', // 必须是https网络路径,不能大于20kb
                    success: function () {
                        // 设置成功
                        console.log('分享成功')
                    }
                }

                // 分享给微信朋友
                wx.updateAppMessageShareData({ ...shareData })

                // //分享到朋友圈”及“分享到QQ空间”
                wx.updateTimelineShareData({ ...shareData })
            },
        });
    })

    // 分享成功回调
    function doShareDone() {
        console.log("分享成功")
    }

    // 取消分享回调
    function doShareCancel() {
        console.log("取消了分享")
    }
    wx.error(res => {
        console.log("wx.config error:", res);
    })
}

export default wxShare
