<template>
    <div class="img-box" v-lazy="vm" :data-src="src">
        <!-- <img v-lazy="lazy" :data-src="src" src="" @load="loadImg" @error="error" alt="" /> -->
        <slot v-if="slotShow"></slot>
        <slot name="err" v-if="errFlag"></slot>
    </div>
</template>

<script>
export default {
    props: {
        src: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            slotShow: true,
            errFlag: false,
            vm: null,
        };
    },
    created() {
        this.vm = this;
    },

    // 这个指令可以放到全局
    directives: {
        lazy: {
            inserted(el, { value }) {
                const imgSrc = el.dataset.src;
                const observer = new IntersectionObserver(([{ isIntersecting }]) => {
                    if (isIntersecting) {
                        // 动态创建 img 标签
                        let img = document.createElement("img");
                        img.src = imgSrc;
                        img.style.width = "100%";
                        img.style.height = "100%";
                        // 添加图片加载完成事件，加载完成，让加载前的样式隐藏
                        img.onload = function () {
                            value.slotShow = false;
                        };
                        (img.onerror = function () {
                            // 加载失败得时候  失败后得样式显示
                            value.errFlag = true;
                            // 让加载前得样式隐藏，
                            value.slotShow = false;
                        }),
                            el.appendChild(img);
                        observer.unobserve(el);
                    }
                });
                observer.observe(el);
            },
        },
    },
    methods: {
    },
};
</script>

<style lang="less" scoped>
.img-box {
    display: flex;
    position: relative;
    overflow: hidden;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>