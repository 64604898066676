<template>
    <div>
        <div style="padding:20px">
            <div v-if="isShowImmu" class="reportCard immune" @click="toReport(1)">
                <span>免疫力检测项目</span>
                <van-icon name="arrow" size="16px" color="#FF7E00"></van-icon>
            </div>
            <div v-if="isShowCovid" class="reportCard covid" @click="toReport(2)">
                <span>新冠检测项目</span>
                <van-icon name="arrow" size="16px" color="#7848FF"></van-icon>
            </div>

        </div>
        <div v-if="!isShowImmu && !isShowCovid" class="emptyPic">
            <img src="../../images/order/empty.png" alt="" style="width:200px;display: inline-block;">
            <div v-if="status == 0" style="font-weight: 400;color: #999999;line-height: 22px;">
                未查询到该用户下有检测结果
            </div>
            <div v-else style="font-weight: 400;color: #999999;line-height: 22px;">检测报告未出，请到订单详情中查看进度</div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            reportData: {},
            isShowImmu: true,
            isShowCovid: true,
            loading: true,
            status: 0,  //没有报告的时候，0表示没有订单，1表示有订单
        }
    },
    created() {
    },
    mounted() {
        let reportData = localStorage.getItem('reportData')
        if (reportData == null || reportData === 'undefined') {
            this.isShowImmu = false
            this.isShowCovid = false
        } else if (JSON.parse(reportData).syncCOVIDReport.length !== 0) {
            this.isShowImmu = true
            this.reportData = JSON.parse(reportData)
        } else if (JSON.parse(reportData).syncImmunityReport.length !== 0) {
            this.isShowCovid = true
            this.reportData = JSON.parse(reportData)
        } else {
            this.isShowImmu = false
            this.isShowCovid = false
        }

        if(JSON.parse(reportData).status !== undefined){
            this.status = JSON.parse(reportData).status
        }

    },
    computed: {},
    methods: {
        // 去报告页
        toReport(index) {
            if (index == 1) {
                // 免疫力检测
                localStorage.setItem('reportList', JSON.stringify(this.reportData.syncImmunityReport))
            } else {
                localStorage.setItem('reportList', JSON.stringify(this.reportData.syncCOVIDReport))
            }
            this.$router.push('/report')
        }
    },
}
</script>
<style lang="scss" scoped>
.reportCard {
  width: 100%;
  height: 62px;
  background: #FFFFFF;
  border-radius: 8px;
  line-height: 62px;
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: 20px;
}

.immune {
  box-shadow: 0px 0px 5px 0px rgba(217, 105, 0, 0.5), inset 0px 0px 3px 0px #FF9128;
  border: 1px solid #FF7E00;
  color: #FF7E00;
}

.covid {
  box-shadow: 0px 0px 5px 0px rgba(120, 72, 255, 0.5), inset 0px 0px 3px 0px #6128FF;
  border: 1px solid #7848FF;
  color: #7848FF;
}

.emptyPic {
  text-align: center;
}
</style>
