<template>
   <div>
      <div style="padding-bottom: 40px;">
         <lazyImg :src="imgs[0]" class="imgTransition" alt=""></lazyImg>
         <lazyImg :src="imgs[1]" class="imgTransition" alt=""></lazyImg>
         <div class="globalText" style="margin: 30px 24px;">免疫力通过固有免疫和适应性免疫两种应答机制来保护我们，它为我们身体打造了三道防线。</div>
         <lazyImg :src="imgs[2]" class="imgTransition" alt=""></lazyImg>
         <div class="globalText" style="margin: 30px 24px;">适应性免疫是后天获得的，其具备显著的个体差异性，相较于固有免疫，适应性免疫拥有记忆性，反应持久且更为强烈。</div>
         <div class="tipBox">
            <div class="tip">Tip</div>
            <div class="content">人类接种牛痘“驯服”天花的过程, 就是适应性免疫应用的经典案例。</div>
         </div>
      </div>

   </div>
</template>
<script>
export default {
   name: 'howProtect',
   data() {
      return {
         imgs: [
            require('../../images/howPro/1.png'),
            require('../../images/howPro/2.png'),
            require('../../images/howPro/3.png')
         ]
      }
   },
   created() {
   },
   mounted() {
      // 页面置顶
      document.documentElement.scrollIntoView()
   },
   computed: {
   },
   methods: {
   },
}
</script>
<style lang="scss" scoped>
.tipBox {
   // width: 327px;
   height: 84px;
   margin: 0 24px;
   background: linear-gradient(90deg, #FFA14A 0%, #FFB84A 100%);
   border-radius: 100px 8px 8px 100px;
   padding: 16px;
   box-sizing: border-box;
   display: flex;
   align-items: center;
   justify-content: space-between;

   .tip {
      width: 48px;
      height: 48px;
      background: #FFFFFF;
      border-radius: 50%;
      text-align: center;
      line-height: 48px;
      font-size: 16px;
      font-weight: 600;
      color: #FFA14A;
   }

   .content {
      font-size: 14px;
      color: #FFFFFF;
      line-height: 22px;
      justify-content: space-between;
      margin-left: 12px;
      flex: 1;
   }
}
</style>
