<template>
    <div class="reportBox">
        <div v-if="reportList.length != 0">
            <div class="reportItem" v-for="(item, index) in      reportList     " :key="index" @click="goto(item.type)">
                <img v-if="item.type == 1" :src="ossUrl + bgc1" alt="" class="reportBgc">
                <img v-else :src="ossUrl + bgc2" alt="" class="reportBgc">
                <div class="reportTitle singleLineOverflowHidden">{{ item.name }}</div>
                <div class="reportTime">
                    <span>采样时间</span>
                    <span style="margin-left:24px;">2022-12-23</span>
                    <span style="margin-left:17px;">12:23</span>
                </div>
                <div class="reportTime">
                    <span>报告时间</span>
                    <span style="margin-left:24px;">2023-01-07</span>
                    <span style="margin-left:17px;">12:23</span>
                </div>
            </div>
        </div>
        <div v-else class="emptyPic">
            <img src="../../images/order/empty.png" alt="" style="width:200px">
            <div style="font-weight: 400;color: #999999;line-height: 22px;">未查询到该用户下有检测结果</div>
        </div>
    </div>
</template>

<script>
const ossUrl = 'https://kindstarbiotech.oss-cn-hangzhou.aliyuncs.com/rosefinch/static/h5/'
export default {
    data() {
        return {
            ossUrl: ossUrl,
            bgc1: 'bgc1.png',
            bgc2: 'bgc2.png',
            reportList: [{
                type: 1,  //1是免疫评估，2是新冠结果
                name: "免疫功能综合评估结果",
                appointDate: '2022-12-01',
                appointTime: '12:23',
                reportDate: '2023-01-02',
                reportTime: '12:23'
            }, {
                type: 2,
                name: "新冠相关免疫力检测结果",
                appointDate: '2022-12-01',
                appointTime: '12:23',
                reportDate: '2023-01-02',
                reportTime: '12:23'
            },]
        }
    },
    created() {
    },
    mounted() {
    },
    computed: {
    },
    methods: {
        goto(type) {
            localStorage.removeItem('st')
            if (type == 1) {
                //免疫评估结果
                this.$router.push('/reportExample/immu')
            } else {
                // 新冠监测结果
                this.$router.push('/reportExample/sars')
            }
        }
    },
}
</script>
<style lang="scss" scoped>
@import url(../../static/css/global.css);

.reportBox {
    padding: 10px 16px;
}

.reportItem {
    position: relative;
    height: 113px;
    border-radius: 25px;
    padding: 16px 20px;
    box-sizing: border-box;
    margin-bottom: 20px;


    .reportTitle {
        position: relative;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 25px;
        letter-spacing: 2px;
        z-index: 999;
    }

    .reportTime {
        position: relative;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 20px;
        letter-spacing: 1px;
        margin-top: 8px;
        z-index: 999;
    }

    .reportBgc {
        width: 100%;
        height: 113px;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.emptyPic {
    margin-top: 100px;
    text-align: center;
}
</style>
