// 配置首页接口
import request from "../../../util/request"

export default class ReportApi {
    // 报告发送短信验证码
    async getCode(phone) {
        const res = await request.get('/apis/order/sendPhone?phone=' + phone)
        return res.data;
    }


    // 查看报告
    async getReport(phone, code) {
        const res = await request.post('/apis/order/report', { phone: phone, code: code })
        return res.data;
    }
}