<template>
  <div id="app">
    <router-view class="router-view"></router-view>
  </div>
</template>

<script>
import wxShare from './util/wxShare';
export default {
  name: 'App',
  created() {
    // 首页置顶
    localStorage.setItem('hst', 0)
    wxShare()
  },
  methods: {

  },
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  font-family: "SourceHanSansCN-Medium";
  letter-spacing: 1.3px;
}

img {
  display: block;
}
</style>
