<template>
   <div>
      <div class="title">康贝测（KB-Tseq）在线订购、采样及报告查询流程</div>
      <!-- 流程图 -->
      <div class="process">
         <div class="processItem" v-for="(item, index) in processes" :key="index">
            <lazyImg class="processIcon imgTransition" :src="item.imgUrl" alt=""></lazyImg>
            <span class="text1">Step {{ index + 1 }}</span>
            <span class="text2">{{ item.content }}</span>
            <div class="line"></div>
         </div>
      </div>
   </div>
</template>
<script>
export default {
   name: 'process',
   data() {
      return {
         processes: [{
            imgUrl: require('../../images/process/process.png'),
            content: '填写调查问卷，提交订单，完成预约'
         }, {
            imgUrl: require('../../images/process/process1.png'),
            content: '短信通知预约成功'
         }, {
            imgUrl: require('../../images/process/process2.png'),
            content: '客服与您联系确认预约信息'
         }, {
            imgUrl: require('../../images/process/process3.png'),
            content: '前往定点机构采样'
         }, {
            imgUrl: require('../../images/process/process4.png'),
            content: '公众号查询检测进度（10个工作日）'
         }, {
            imgUrl: require('../../images/process/process5.png'),
            content: '公众号查询免疫力评估报告'
         }, {
            imgUrl: require('../../images/process/process6.png'),
            content: '公众号输入关键字查看相应学术名词解释或联系客服寻求支持'
         }]
      }
   },
   created() {
   },
   mounted() {
      // 页面置顶
      document.documentElement.scrollIntoView()
   },
   computed: {
   },
   methods: {
   },
}
</script>
<style lang="scss" scoped>
.title {
   font-size: 18px;

   color: #333333;
   line-height: 25px;
   letter-spacing: 2px;
   margin: 20px 24px 0 24px;
}

.process {
   margin: 20px 30px;

   .processItem {
      position: relative;
      width: 100%;
      min-height: 90px;
   }
}

.processIcon {
   position: absolute;
   top: 0;
   left: 0;
   width: 50px;
   height: 50px;
}

.text1 {
   position: absolute;
   top: 0;
   left: 70px;
   font-size: 16px;

   color: #333333;
   line-height: 22px;
}

.text2 {
   position: absolute;
   top: 30px;
   left: 70px;
   font-size: 14px;

   color: #808080;
   line-height: 22px;
   letter-spacing: 1px;
   text-align: justify;
}

.line {
   position: absolute;
   top: 50px;
   left: 25px;
   height: 40px;
   width: 1px;
   border-left: 1px dashed #8DB7FF;
}

.process .processItem:nth-child(7) .line {
   border: 0 !important;
}
</style>
