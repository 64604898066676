import Vue from 'vue';
import VueRouter from 'vue-router';
import homeView from "@/views/home/home"
import reportView from "@/views/reportExample/report"
import reportSearch from "@/views/report/search"
import reportList from "@/views/report/list"
import process from "@/views/home/process"
import introdution from "@/views/home/introdution"
import anaysis from "@/views/home/anaysis"
import howProtect from "@/views/home/howProtect"
import info from "@/views/home/info"
import miniUniverse from "@/views/home/miniUniverse"
import immuTest from '@/views/home/immuTest'
import immu from "@/views/reportExample/immu/index"
import sars from "@/views/reportExample/sars/index"
import { login } from "../util/weiXinUtils"

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		redirect: '/home',
		meta: {
			hidden: true
		}
	},
	{
		path: '/home',
		name: 'home',
		component: homeView,
		meta: {
			title: "康贝测（KB-Tseq）"
		}
	},
	{
		path: '/home/process',
		name: 'process',
		component: process,
		meta: {
			title: '康贝测（KB-Tseq）'
		}
	},
	{
		path: '/home/introdution',
		name: 'introdution',
		component: introdution,
		meta: {
			title: '什么是免疫力？'
		}
	},
	{
		path: '/home/anaysis',
		name: 'anaysis',
		component: anaysis,
		meta: {
			title: '如何进行免疫力分析？'
		}
	}, {
		path: '/home/howProtect',
		name: 'howProtect',
		component: howProtect,
		meta: {
			title: '免疫力如何保护我们？'
		}
	}, {
		path: '/home/info',
		name: 'info',
		component: info,
		meta: {
			title: '能告诉我什么信息？'
		}
	}, {
		path: '/home/miniUniverse',
		name: 'miniUniverse',
		component: miniUniverse,
		meta: {
			title: '“小宇宙” 图'
		}
	},
	{
		path: '/home/immuTest',
		name: 'immuTest',
		component: immuTest,
		meta: {
			title: '新冠相关免疫力检测'
		}
	},
	{
		path: '/reportExample',
		name: 'reportExample',
		component: reportView,
		meta: {
			title: '用户报告'
		},
		// children: report.children
	}, {
		path: "/reportExample/immu",
		name: 'immu',
		component: immu,
		meta: {
			title: '免疫功能综合评估结果'
		},
	}, {
		path: "/reportExample/immuAge",
		name: 'immuAge',
		component: () => import("@/views/reportExample/immu/age"),
		meta: {
			title: '免疫年龄'
		},
	}, {
		path: "/reportExample/immuVariety",
		name: 'immuVariety',
		component: () => import("@/views/reportExample/immu/variety"),
		meta: {
			title: 'T细胞多样性'
		},
	}, {
		path: "/reportExample/immuVarietyDepth",
		name: 'immuVarietyDepth',
		component: () => import("@/views/reportExample/immu/varietyDepth"),
		meta: {
			title: 'T细胞多样性深度分析'
		},
	}, {
		path: "/reportExample/immuStress",
		name: 'immuStress',
		component: () => import("@/views/reportExample/immu/stress"),
		meta: {
			title: '免疫应激性'
		},
	}, {
		path: "/reportExample/immuSummary",
		name: 'immuSummary',
		component: () => import("@/views/reportExample/immu/summary"),
		meta: {
			title: '报告小结'
		},
	}, {
		path: "/reportExample/immuVisible",
		name: 'immuVisible',
		component: () => import("@/views/reportExample/immu/visible"),
		meta: {
			title: '“免疫力”小宇宙'
		},
	}, {
		path: "/reportExample/immuProcess",
		name: 'immuProcess',
		component: () => import("@/views/reportExample/immu/process"),
		meta: {
			title: '检测流程及声明'
		},
	}, {
		path: "/reportExample/immuHealth",
		name: 'immuHealth',
		component: () => import("@/views/reportExample/immu/health"),
		meta: {
			title: '健康管理建议'
		},
	}, {
		path: "/reportExample/immuCheck",
		name: 'immuCheck',
		component: () => import("@/views/reportExample/immu/check"),
		meta: {
			title: '复查建议'
		},
	},
	{
		path: "/reportExample/sars",
		name: 'sars',
		component: sars,
		meta: {
			title: '新冠相关免疫力检测结果'
		},
	}, {
		path: "/reportExample/sarsIgM",
		name: 'sarsIgM',
		component: () => import("@/views/reportExample/sars/igM"),
		meta: {
			title: '新冠特异性IgM抗体'
		},
	}, {
		path: "/reportExample/sarsIgG",
		name: 'sarsIgG',
		component: () => import("@/views/reportExample/sars/igG"),
		meta: {
			title: '新冠特异性IgG抗体'
		},
	}, {
		path: "/reportExample/sarsNeutralizing",
		name: 'sarsNeutralizing',
		component: () => import("@/views/reportExample/sars/neutralizing"),
		meta: {
			title: '新冠中和抗体'
		},
	}, {
		path: "/reportExample/sarsImmunofingerprint",
		name: 'sarsImmunofingerprint',
		component: () => import("@/views/reportExample/sars/immunofingerprint"),
		meta: {
			title: '新冠特异性T细胞免疫指纹'
		},
	}, {
		path: '/order',
		name: 'order',
		component: () => import("@/views/order/index"),
		meta: {
			title: '订单服务'
		},
	}, {
		path: '/addOrder',
		name: 'addOrder',
		component: () => import("@/views/order/addOrder/index"),
		meta: {
			title: '创建预约'
		},
	}, {
		path: '/confirmOrder',
		name: 'confirmOrder',
		component: () => import("@/views/order/addOrder/confirm"),
		meta: {
			title: '订单确认'
		},
	}, {
		path: '/paySuccess',
		name: 'paySuccess',
		component: () => import("@/views/order/addOrder/paySuccess"),
		meta: {
			title: '支付结果'
		},
	}, {
		path: '/orderHealth',
		name: 'orderHealth',
		component: () => import("@/views/order/addOrder/health"),
		meta: {
			title: '健康调查问卷'
		},
	}, {
		path: '/agreement',
		name: 'agreement',
		component: () => import("@/views/order/addOrder/agreement"),
		meta: {
			title: '知情同意书'
		},
	}, {
		path: '/userRights',
		name: 'userRights',
		component: () => import("@/views/order/addOrder/userRights"),
		meta: {
			title: '使用须知'
		},
	}, {
		path: '/orderDetail',
		name: 'orderDetail',
		component: () => import("@/views/order/detail/index"),
		meta: {
			title: '订单详情'
		},
	}, {
		path: '/report/list',
		name: 'reportList',
		component: reportList,
		meta: {
			title: '用户报告'
		},
	}, {
		path: '/report',
		name: 'report',
		component: () => import("@/views/report/report"),
		meta: {
			title: '用户报告'
		}
	}, {
		path: '/report/search',
		name: 'reportSearch',
		component: reportSearch,
		meta: {
			title: '报告查询'
		}
	}, {
		path: "/report/immu",
		name: 'reportImmu',
		component: () => import("@/views/report/immu"),
		meta: {
			title: '免疫功能综合评估结果'
		}
	}, {
		path: "/report/sars",
		name: 'reportSars',
		component: () => import("@/views/report/sars"),
		meta: {
			title: '新冠相关免疫力检测结果'
		}
	}, {
		path: "/report/immuAge",
		name: 'reportImmuAge',
		component: () => import("@/views/report/immu/age"),
		meta: {
			title: '免疫年龄'
		}
	}, {
		path: "/report/immuVariety",
		name: 'reportImmuVariety',
		component: () => import("@/views/report/immu/variety"),
		meta: {
			title: 'T细胞多样性'
		}
	}, {
		path: "/report/immuVarietyDepth",
		name: 'reportImmuVarietyDepth',
		component: () => import("@/views/report/immu/varietyDepth"),
		meta: {
			title: 'T细胞多样性深度分析'
		},
	}, {
		path: "/report/immuStress",
		name: 'reportImmuStress',
		component: () => import("@/views/report/immu/stress"),
		meta: {
			title: '免疫应激性'
		}
	}, {
		path: "/report/immuSummary",
		name: 'reportImmuSummary',
		component: () => import("@/views/report/immu/summary"),
		meta: {
			title: '报告小结'
		},
	}, {
		path: "/report/immuVisible",
		name: 'reportImmuVisible',
		component: () => import("@/views/report/immu/visible"),
		meta: {
			title: '“免疫力”小宇宙'
		},
	}, {
		path: "/report/immuProcess",
		name: 'reportImmuProcess',
		component: () => import("@/views/report/immu/process"),
		meta: {
			title: '检测流程及声明'
		},
	}, {
		path: "/report/immuHealth",
		name: 'reportImmuHealth',
		component: () => import("@/views/report/immu/health"),
		meta: {
			title: '健康管理建议'
		},
	}, {
		path: "/report/immuCheck",
		name: 'reportImmuCheck',
		component: () => import("@/views/report/immu/check"),
		meta: {
			title: '复查建议'
		},
	}, {
		path: "/report/sarsIgM",
		name: 'reportSarsIgM',
		component: () => import("@/views/report/sars/igM"),
		meta: {
			title: '新冠特异性IgM抗体'
		},
	}, {
		path: "/report/sarsIgG",
		name: 'reportSarsIgG',
		component: () => import("@/views/report/sars/igG"),
		meta: {
			title: '新冠特异性IgG抗体'
		},
	}, {
		path: "/report/sarsNeutralizing",
		name: 'reportSarsNeutralizing',
		component: () => import("@/views/report/sars/neutralizing"),
		meta: {
			title: '新冠中和抗体'
		},
	}, {
		path: "/report/sarsImmunofingerprint",
		name: 'reportSarsImmunofingerprint',
		component: () => import("@/views/report/sars/immunofingerprint"),
		meta: {
			title: '新冠特异性T细胞免疫指纹'
		},
	}
];

const router = new VueRouter({
	routes,
	// mode: 'history'
});

// 这里就可以进行vue-router的beforeEach拦截了，你也可以放其他地方，我比较喜欢放这
router.beforeEach((to, from, next) => {
	// 登录
	login(to)

	// 网页标题
	document.title = to.meta.title || '';

	// 置顶
	if (to.path.indexOf("/report/immu") == -1 && to.path.indexOf("/reportExample/immu") == -1 && to.path.indexOf("/report/sars") == -1 && to.path.indexOf("/reportExample/sars") == -1 && to.path.indexOf('/home') && to.path != '/reportExample') {
		// 部分页面不要置顶
		window.scroll({ top: 0, left: 0 });
	}
	next()
})

export default router;
