<template>
    <div style="padding:30px 24px;">
        <div class="globalTitle" style="color: #F23D4E;margin-bottom: 16px;">新冠感染与免疫失衡?</div>
        <div class="globalText" style="margin: 16px 0;">
            当新冠病毒（SARS-CoV-2）入侵人体时 , 会遇到免疫系统的强烈反击 , 适应性免疫的 T/B 细胞是主要的参与者。一番大战之后 , 虽然最终获胜，但机体也随之大伤元气 , 人体的免疫细胞被大量消耗 ,
            由此导致身体虚弱无力 , 出现免疫失衡的症状。
        </div>
        <img src="../../images/home/immuTest1.png" alt="" class="center" style="width:200px;margin:30px 0;">

        <div class="globalTitle" style="color: #F23D4E;margin-bottom: 16px;">大家关心</div>
        <div class="globalText" style="margin: 16px 0;">
            自身免疫力是否恢复到正常状态？能否从容应对下次新冠感染，避免免疫失衡、免疫耗竭？
        </div>
        <img src="../../images/home/immuTest2.png" alt="" class="center" style="width:200px;margin:30px 0;">

        <div class="globalTitle" style="color: #F23D4E;margin-bottom: 16px;">新冠特异性抗体</div>
        <div class="globalText" style="margin: 16px 0;">
            新冠抗体是人体感染新型冠状病毒（SARS-CoV-2）或接种疫苗后，由B淋巴细胞产生的可与相应病毒发生特异性结合反应的免疫球蛋白，是适应性免疫系统做出的免疫防御反应。本检测不仅会对体内
            IgM/IgG抗体进行定量，同时也对中和抗体进行定量，给出更全面的评估。
        </div>
        <img src="../../images/home/immuTest3.png" alt="" class="center" style="width:200px;margin:30px 0;">

        <div class="globalTitle" style="color: #F23D4E;margin-bottom: 16px;">新冠特异性T细胞免疫指纹</div>
        <div class="globalText" style="margin: 16px 0;">
            新型冠状病毒（SARS-CoV-2）感染或接种疫苗后会引起T细胞介导的细胞免疫，可以通过对新冠病毒特异性T细胞的 TCR
            分析进行评价。新冠特异性T细胞存在，标志着正在或发生过新冠感染（或接种过疫苗），相较于抗体，新冠特异性T细胞在体内存留时间更长，这也许是我们避免新冠重症的主要因素。
        </div>
        <img src="../../images/home/immuTest4.png" alt="" class="center" style="width:200px;margin:30px 0;">
    </div>
</template>
<script>
export default {
    data() {
        return {
        }
    },
    created() {
    },
    mounted() {
        // 页面置顶
        document.documentElement.scrollIntoView()
    },
    computed: {
    },
    methods: {
    },
}
</script>
<style lang="scss" scoped>
.center {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}
</style>
