<template>
   <div>
      <lazyImg :src="ossUrl + 'infoBanner.png'" class="banner imgTransition" alt=""></lazyImg>
      <div style="padding:20px 24px 20px 24px ">
         <div class="globalText" style="margin: 20px 0;">
            <div style="margin-bottom: 14px;">医学研究表明，人体90%以上的疾病与免疫系统失调密切相关，免疫功能过高，易患自身免疫性疾病；免疫功能低下，易患感染性疾病或肿瘤。</div>
            <div style="margin-bottom: 14px;">
               免疫功能受很多因素的影响，除了年龄是不可避免的因素之外，药物、生活不规律、工作压力、饮食不均衡、情绪不稳定等因素都会导致免疫功能失调，免疫功能状态会随着身体健康状态的改变而发生变化。</div>
            <div>同时免疫功能的状态变化与疾病的发生和发展密切相关，定期进行免疫健康状态评估，能够帮助您了解身体的综合抗病能力，及时发现免疫失衡问题，选择更适合您的健康管理方案。</div>
         </div>

         <!-- 列表 -->
         <div class="infos">
            <div class="infoItem">
               <div class="left">
                  <div class="miniText">
                     <div>T细胞</div>
                     <div>多样性</div>
                  </div>
               </div>
               <div class="right">淋巴细胞种类愈多，均一性愈好，则疾病抵抗力愈强</div>
            </div>
            <div class="infoItem">
               <div class="left">
                  <div class="miniText">
                     <div>免疫</div>
                     <div>年龄</div>
                  </div>
               </div>
               <div class="right">生理年龄与免疫年龄可能存在差异，这即是同龄人中有人“更健康”的原因。</div>
            </div>
            <div class="infoItem">
               <div class="left">
                  <div class="miniText">
                     <div>免疫</div>
                     <div>指纹</div>
                  </div>
               </div>
               <div class="right">将检测到的T细胞克隆与数据库进行比对，进行大数据前瞻性分析。</div>
            </div>
         </div>

         <div class="globalTitle" style="color: #42B89F;margin: 40px 0 16px 0;">康贝测 (KB-Tseq) 与其他免疫力评估方法的比较</div>
         <div class="globalText" style="margin: 20px 0;">能否获得足够的信息，是免疫力评估准确与否的重要标志，康贝测(KB-Tseq)的结果拥有数以万计的海量信息支撑。</div>
         <div class="picBox">
            <div class="picItem">
               <lazyImg :src="ossUrl + 'left.png'" class="pic imgTransition" alt=""></lazyImg>
               <span>其他方法</span>
            </div>
            <div class="picItem">
               <lazyImg :src="ossUrl + 'right.png'" class="pic imgTransition" alt=""></lazyImg>
               <span>康贝测 (KB-Tseq)</span>
            </div>
         </div>
         <div class="globalText" style="margin: 20px 0;">
            <div style="margin-bottom: 14px;">信息就如同描绘图画的像素。普通的免疫细胞分类或流式细胞术检测，只能得到数条或数十条信息，由其描绘的结果更适合对已知事物的判断，比如我能看到一颗星星。
            </div>
            <div>康贝测(KB-Tseq)免疫组库测序技术，以亿为单位的海量数据进行结果描绘，不仅让我们能看到满眼星辰，还给了我们探索其奥秘的可能。</div>
         </div>
      </div>
   </div>
</template>
<script>
const ossUrl = 'https://kindstarbiotech.oss-cn-hangzhou.aliyuncs.com/rosefinch/static/h5/'
export default {
   ame: 'info',
   data() {
      return {
         ossUrl: ossUrl,
      }
   },
   created() {
   },
   mounted() {
      // 页面置顶
      document.documentElement.scrollIntoView()
   },
   computed: {
   },
   methods: {
   },
}
</script>
<style lang="scss" scoped>
.text {
   font-size: 14px;

   color: #333333;
   line-height: 22px;
   margin: 20px 0;
   text-align: justify;
}

.title {
   font-size: 18px;

   color: #42B89F;
   line-height: 25px;
   letter-spacing: 2px;
   margin: 40px 0 16px 0;
}

.infos {
   padding: 20px 16px 0 16px;
   background: #FFFFFF;
   box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.12);
   border-radius: 8px;
   border: 1px solid #00AE88;
   box-sizing: border-box;
}

.infoItem {
   display: flex;
   align-items: center;
   margin-bottom: 20px;

   .left {
      width: 64px;
      height: 64px;
      background: linear-gradient(180deg, #00D6BB 0%, #00AC85 100%);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
   }

   .right {
      font-size: 14px;

      color: #333333;
      line-height: 20px;
      letter-spacing: 1px;
      flex: 1;
      text-align: justify;
      margin-left: 12px;
   }
}

.miniText {
   width: 64px;
   height: 64px;
   font-size: 14px;

   color: #FFFFFF;
   line-height: 18px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

.picBox {
   // width: 327px;
   height: 150px;
   background: #FFFFFF;
   box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.12);
   border-radius: 8px;
   border: 1px solid #00AE88;
   padding: 20px 12px 16px 12px;
   box-sizing: border-box;
   display: flex;
   align-items: center;
   justify-content: space-between;

   .picItem {
      width: 146px;
      height: 114px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #42B89F;
      line-height: 20px;
   }

   .pic {
      width: 98px;
   }
}
</style>
