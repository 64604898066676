<template>
   <div style="padding-bottom:20px">
      <lazyImg :src="ossUrl + 'banner.png'" class="imgTransition" alt="" style="width:100%"></lazyImg>
      <div style="padding:20px 24px 20px 24px;">
         <div class="text" style="margin-bottom:40px">
            <div>艺术源于生活，科学始于探索。在我们用客观数据描述T淋巴细胞多样性的同时，也希望将此科学的数字用更具视觉化的方式进行呈现，“小宇宙”图即诞生于此。 </div>
            <div> 图中的每一个圆形色块代表一种T细胞群体，色块的面积大小代表该T细胞群体的相对数量。</div>
         </div>
         <div class="imgBox">
            <lazyImg :src="ossUrl + '1.png'" class="imgTransition center" alt="" style="width:100%"></lazyImg>

         </div>
         <div class="imgBox">
            <lazyImg :src="ossUrl + '2.png'" class="imgTransition center" alt="" style="width:100%"></lazyImg>
         </div>
         <!-- <img src="../../images/miniUni/2.png" alt="" class="center" style="margin-bottom: 16px;">s -->
         <div class="text" style="margin-top: 40px;">如同您仰望星空感叹璀璨星河的瑰丽一样，我们也希望您探索自身，发现自己“小宇宙”的神奇！</div>
         <div class="tip">康圣贝泰提供个性化“小宇宙”图挂画定制服务</div>
      </div>
      <div class="buttonBox">
         <button @click="buy">去定制自己的小宇宙</button>
      </div>
   </div>
</template>
<script>
const ossUrl = 'https://kindstarbiotech.oss-cn-hangzhou.aliyuncs.com/rosefinch/static/h5/'
export default {
   name: 'miniUniverse',
   data() {
      return {
         ossUrl: ossUrl,
      }
   },
   created() {
   },
   mounted() {
      // 页面置顶
      document.documentElement.scrollIntoView()
   },
   computed: {

   },
   methods: {
      // 去下单
      buy() {
         this.clearStore()
         localStorage.setItem('returnLoc', '/home')
         this.$router.push("/addOrder")
      },
      // 清除store数据
      clearStore() {
         localStorage.removeItem('order')
         localStorage.removeItem('orderInfo')

         // 清空健康调查问卷
         localStorage.removeItem('healthSurvey')
         localStorage.removeItem('isHealthAchieve')

         // 清空用户协议
         localStorage.removeItem('agreement')
         localStorage.removeItem('userRights')
         localStorage.removeItem('isReadonly')

         localStorage.removeItem('hst')
      },
   },
}
</script>
<style lang="scss" scoped>
.text {
   font-size: 14px;

   color: #333333;
   line-height: 22px;
   letter-spacing: 1px;
   text-align: justify;
   margin: 20px 0;
}

.buttonBox {
   height: 60px;
   background: #FFFFFF;
   box-shadow: 0px -1px 1px 0px rgba(0, 0, 0, 0.12);
   padding: 10px 24px;
   box-sizing: border-box;

   button {
      width: 100%;
      height: 40px;
      background: #1477FD;
      border-radius: 8px;
      text-align: center;
      line-height: 40px;
      font-size: 14px;

      color: #FFFFFF;
      line-height: 20px;
      border: none;
   }
}

.imgBox {
   width: 327px;
   height: 208px;
   background: #FFFFFF;
   box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.12);
   border-radius: 8px;
   margin: 20px 0;
   position: relative;
   left: 50%;
   transform: translateX(-50%)
}

.center {
   position: relative;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
}

.tip {
   font-size: 16px;

   color: #1477FD;
   line-height: 22px;
   margin-top: 30px
}
</style>
