<template>
    <div>
        <div style="padding:16px">
            <div class="title">
                填写手机
            </div>
            <div class="display_flex" style="margin-top: 16px;">
                <van-search placeholder="输入手机号搜索" v-model="phone" type="number" maxlength="11" />
                <button class="sendCode" @click="sendCode" v-if="countDownNum === 120 || countDownNum === -1"> 发送验证 </button>
                <button class="sendCode" style="background-color: #E6E6E6; color:#666666" v-else>{{ countDownNum }}s</button>
            </div>
        </div>
        <van-dialog v-model="codeShow" title="验证码" show-cancel-button confirm-button-color="#1477FD" @confirm="confirm"
            @cancel="cancel" :before-close="onBeforeClose">
            <div class="codeBox">
                <div v-for="(item, index ) in len" :key="index" class="code">
                    <span v-if="code.length > index"></span>
                </div>
                <van-field v-model="code" type="number" maxlength="6" />
            </div>
        </van-dialog>
    </div>
</template>
<script>
import { Toast } from 'vant';
import ReportApi from './api';
import { login } from '../../util/weiXinUtils';
const reportApi = new ReportApi()
export default {
    data() {
        return {
            phone: '',
            code: '',
            codeShow: false,
            len: 6,
            countDownNum: 120
        }
    },
    created() {
    },
    mounted() {
        login()
    },
    computed: {
    },
    methods: {
        // 校验手机号
        checkPhone() {
            let phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/
            if (!this.phone.trim()) {
                Toast('请填写手机号');
                return false
            } else if (this.phone.trim() && !phonereg.test(this.phone.trim())) {
                Toast('手机号格式不对');
                return false
            }
            return true
        },
        // 倒计时
        countDown() {
            let timer = setInterval(() => {
                this.countDownNum = this.countDownNum - 1
                if (this.countDownNum === -1) {
                    clearInterval(timer)
                    this.countDownNum = 120
                }
            }, 1000)
        },
        //发送验证
        sendCode() {
            if (this.checkPhone()) {
                // 获取验证码
                reportApi.getCode(this.phone).then(() => {
                    this.codeShow = true
                    this.countDown()
                }).catch(()=>{
                    // 验证码已发送
                    this.codeShow = true
                })
            }
        },

        // 根据验证码获取报告信息
        async getReport() {
            let res = await reportApi.getReport(this.phone, this.code)
            this.clearStore()
            localStorage.setItem('reportData', JSON.stringify(res.data))
            this.$router.push("/report/list")
        },
        // 清除缓存
        clearStore() {
            localStorage.removeItem('reportData')
            localStorage.removeItem('reportList')
            localStorage.removeItem('report')
        },

        // 验证码弹框点击确定
        confirm() {
            if (this.code.length === 6) {
                // 这里提交请求
                this.getReport()
            }
        },
        // 弹框点击取消
        cancel() {
            this.code = ''
        },
        async onBeforeClose(action, done) {
            if (action === "confirm") { // 点击确定走这里
                if (this.code.length !== 6) {
                    console.log(this.code)
                    return done(false)
                } else {
                    done(true) // 关闭弹窗, true可以省略
                    this.code = ''
                }
            } else { // 点击取消走这里
                done(true) // 关闭弹窗, true可以省略
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.title {
    padding: 17px 0 5px 0;
    font-size: 16px;

    color: #333333;
    line-height: 22px;
    display: flex;
    align-items: center;
}

.title::before {
    content: '';
    width: 2px;
    height: 10px;
    background: #1477FD;
    border-radius: 1px;
    margin-right: 8px;
}

.sendCode {
    flex: 1;
    height: 36px;
    background: #1477FD;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 14px;
    color: #FFFFFF;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.van-search {
    width: 241px;
    height: 36px;
    padding: 0 !important;
    margin-right: 12px;
}

.van-search__content {
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #CCCCCC;

}

.van-field__control {
    font-size: 12px;

    color: #999999;
}

.codeBox {
    display: flex;
    align-items: center;
    padding: 28px 20px;
    justify-content: space-between;

    .van-cell {
        width: 100%;
        position: absolute;
        opacity: 0;
    }
}

.code {
    width: 32px;
    height: 32px;
    background: #F8F9FA;
    border-radius: 8px;
    padding: 0;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    border: 0;

    span {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        background-color: #555;
        display: inline-block;
    }
}

.van-dialog {
    width: 282px;
}
</style>
