<template>
    <div>
        <div class="home">
            <lazyImg :src="imgs[0]" class="banner" alt=""></lazyImg>
            <div class="mainBox">
                <div class="globalText" style="margin: 30px 0 20px 0;">描述一个独属于自己免疫系统的故事</div>
                <div class="problemCard globalText" v-for="(item, index) in cards" :key='index'
                    :style="{ backgroundImage: 'url(' + item.bgc + ')' }" @click="goto(index)">
                    <div class="name">{{ item.name }}</div>
                    <div class="more">
                        <div>了解更多</div>
                        <van-icon name="arrow" color="#FFFFFF" size="12px" />
                    </div>
                </div>

                <!-- 免疫力检测 -->
                <div class="testImmu" @click="toProcess">
                    <div class="globalText" style="color: #FFFFFF;line-height: 20px;">
                        <div style="margin-bottom: 8px;">3ml血液，了解自己的免疫力</div>
                        <div>头部体检机构全方位为你服务</div>
                    </div>
                    <div class="testIcons">
                        <div class="testItem">
                            <img src="../../images/home/1.png" alt="" style="margin-bottom: 12px;width: 33px;height: 32px;">
                            <div class="testArrow">
                                <img src="../../images/home/point.png" alt="" style="width:8px;height: 8px;">
                                <img src="../../images/home/arrow.png" alt=""
                                    style="width: 20vw; margin-left: calc(7vw - 14px);" />
                            </div>
                            <span style="font-size: 12px;  -webkit-transform:scale(0.833);">在线购买<br />免疫检测服务</span>
                        </div>
                        <div class="testItem">
                            <img src="../../images/home/2.png" alt="" style="margin-bottom: 12px;width: 33px;height: 32px;">
                            <div class="testArrow">
                                <img src="../../images/home/point.png" alt="" style="width:8px;height: 8px;">
                                <img src="../../images/home/arrow.png" alt=""
                                    style="width: 20vw; margin-left: calc(7vw - 14px);" />
                            </div>
                            <span style="font-size: 12px;  -webkit-transform:scale(0.833);">指定医疗机构<br />采集3ml血液</span>
                        </div>
                        <div class="testItem">
                            <img src="../../images/home/3.png" alt="" style="margin-bottom: 12px;width: 33px;height: 32px;">
                            <img src="../../images/home/point.png" alt=""
                                style="margin-bottom: 14px;width:8px;height: 8px;">
                            <span style="font-size: 12px;  -webkit-transform:scale(0.833);">在线查阅报告</span>
                        </div>
                    </div>
                    <div class="gotoNext">
                        点击了解更多
                        <van-icon name="arrow" color="#FFFFFF" size="14px" />
                    </div>
                </div>

                <!-- 免疫力评估 -->
                <div class="immu" @click="gotoReport">
                    <div class="globalText" style="color: #FFFFFF;line-height: 20px;">
                        <div style="margin-bottom: 8px;">给自己和家人一份免疫力评估</div>
                        <div>创建免疫图谱，免疫力状态一目了然</div>
                    </div>
                    <div class="immuIcons">
                        <div v-for="(item, index) in immuIcons" :key="index" class="immuItem">
                            <img :src="item.src" alt="" style="margin-bottom: 7px;width: 38px;height: 38px;">
                            <span style="margin-top: 12px;">{{ item.name }}</span>
                        </div>
                    </div>
                    <div class="gotoNext">
                        点击查看报告示例
                        <van-icon name="arrow" color="#FFFFFF" size="14px" />
                    </div>
                </div>

                <!-- 常见问题 -->
                <div>
                    <div class="usualProblem">常见问题
                    </div>
                    <van-collapse v-model="activeNames">
                        <van-collapse-item title="如何进行采样？" name="1">
                            <div class="detail">线上下单后，客服人员会与您联系，为您预约公立医院体检中心或三方体检机构提供线下采样服务，具体流程请点击链接。</div>
                            <div class="detailButton" @click="toProcess">订单确认及采样流程</div>
                            <template #right-icon>
                                <van-icon name="arrow" color="#22AF81"
                                    class="van-icon van-icon-arrow van-cell__right-icon" />
                            </template>
                        </van-collapse-item>
                        <van-collapse-item title="多长时间可以出具报告？" name="2">
                            <div class="detail">康贝测（KB-Tseq）免疫功能评估是基于高通量测序技术的免疫组库分析，检测流程复杂，样本抵达实验室后10个工作日内出具报告。</div>
                            <template #right-icon>
                                <van-icon name="arrow" color="#22AF81"
                                    class="van-icon van-icon-arrow van-cell__right-icon" />
                            </template>
                        </van-collapse-item>
                        <van-collapse-item title="在哪里查看我的免疫力报告？" name="3">
                            <div class="detail">检测完毕后，您会收到短信通知，并可通过康圣贝泰微信公众号查询免疫功能评估报告，报告查询入口如下。</div>
                            <div class="detailButton" @click="toReport">报告查询</div>
                            <template #right-icon>
                                <van-icon name="arrow" color="#22AF81"
                                    class="van-icon van-icon-arrow van-cell__right-icon" />
                            </template>
                        </van-collapse-item>
                    </van-collapse>
                </div>
            </div>

        </div>

        <div style="width:100%;height:60px;"></div>
        <div>
            <div class="bottom">
                <div class="cuService" @click="call">
                    <img style="width:24px;height: 24px;" src="../../images/home/cus.png" alt="">
                    <span>客服</span>
                </div>
                <button class="buy" @click="buy">立即购买</button>
            </div>
        </div>

        <van-dialog v-model="show" title="客服热线" show-cancel-button>
            手机号
        </van-dialog>
    </div>
</template>
<script>
import { Dialog } from 'vant';
export default {
    name: 'home',
    data() {
        return {
            show: false,
            imgs: [require('../../images/home/banner.png')],
            activeNames: [],
            cards: [{
                name: '什么是免疫力？',
                bgc: require('../../images/home/bgc1.png')
            }, {
                name: '免疫力如何保护我们？',
                bgc: require('../../images/home/bgc2.png')
            }, {
                name: '康贝测（KB-Tseq）如何进行免疫力分析？',
                bgc: require('../../images/home/bgc3.png')
            }, {
                name: '康贝测（KB-Tseq）能告诉我什么信息？',
                bgc: require('../../images/home/bgc4.png')
            }, {
                name: '什么是康贝测（KB-Tseq）“小宇宙”图？',
                bgc: require('../../images/home/bgc5.png')
            }, {
                name: '新冠相关免疫力检测',
                bgc: require('../../images/home/bgc6.png')
            }],
            immuIcons: [{
                src: require('../../images/home/immuIcon1.png'),
                name: '图谱'
            }, {
                src: require('../../images/home/immuIcon2.png'),
                name: '家人'
            }, {
                src: require('../../images/home/immuIcon3.png'),
                name: '健康'
            }],
            Icons: [{
                src: require('../../images/home/1.png'),
                name: "在线购买阅报告免疫检测服务",

            }, {
                src: require('../../images/home/2.png'),
                name: "指定医疗机构采集3ml血液",
            }, {
                src: require('../../images/home/3.png'),
                name: "在线查阅报告",
            }],
        }
    },
    created() {
        
    },
    mounted() {
        if (window.history && window.history.pushState) {
            // 向历史记录中插入了当前页
            history.pushState(null, null, document.URL);
            window.addEventListener('popstate', this.goBack, false);
        }
        let scrollTop = localStorage.getItem('hst')
        if (scrollTop != null && scrollTop != 'undefined') {
            window.scroll({ top: scrollTop, left: 0 });
        } else {
            window.scroll({ top: 0, left: 0 })
        }
    },
    computed: {
    },
    destroyed() {
        window.removeEventListener('popstate', this.goBack, false);
    },
    methods: {
        // 流程页
        toProcess() {
            this.getScrollTop()
            this.$router.push('/home/process')
        },
        // 记录滚动信息
        getScrollTop() {
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            localStorage.setItem('hst', scrollTop)
        },
        goto(index) {
            this.getScrollTop()
            switch (index) {
                case 0:
                    this.$router.push("/home/introdution")
                    break
                case 1:
                    this.$router.push("/home/howProtect")
                    break
                case 2:
                    this.$router.push("/home/anaysis")
                    break
                case 3:
                    this.$router.push("/home/info")
                    break
                case 4:
                    this.$router.push("/home/miniUniverse")
                    break
                case 5:
                    this.$router.push("/home/immuTest")
            }
        },
        // 报告页面
        gotoReport() {
            this.getScrollTop()
            this.$router.push("/reportExample")
        },
        // 去下单
        buy() {
            this.clearStore()
            localStorage.setItem('returnLoc', '/home')
            this.$router.push("/addOrder")
        },
        // 去报告查询页
        toReport() {
            this.getScrollTop()
            this.$router.push("/report/search")
        },
        // 清除store数据
        clearStore() {
            localStorage.removeItem('order')
            localStorage.removeItem('orderInfo')

            // 清空健康调查问卷
            localStorage.removeItem('healthSurvey')
            localStorage.removeItem('isHealthAchieve')

            // 清空用户协议
            localStorage.removeItem('agreement')
            localStorage.removeItem('userRights')
            localStorage.removeItem('isReadonly')

            localStorage.removeItem('hst')
        },
        goBack() {
            history.pushState(null, null, document.URL);
        },
        // 拨打电话
        call() {
            Dialog.confirm({
                title: '客服热线',
                message: '027-65525254\n\n工作日:9:00-12:00;13:30-17:30',
                confirmButtonText: '拨打',
                confirmButtonColor: '#007dfa'
            }).then(() => {
                // on confirm
                window.location.href = 'tel:027-65525254';
            }).catch(() => {
                // on cancel
            });
        }
    }
}
</script>
<style lang="scss" scoped>
@import url(../../static/css/global.css);

::v-deep [role="button"]:active {
    // 去除按钮点击阴影
    background-color: white;
    color: white;
}

.home {
    padding: 20px 24px;
}

.banner {
    // width: 327px;
    height: 140px;
}

.problemCard {
    position: relative;
    // width: 327px;
    height: 98px;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.12);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 8px;
    padding-left: 20px;
    box-sizing: border-box;
    margin-bottom: 20px;

    .name {
        position: absolute;
        top: 16px;
        width: 221px;
        color: #FFFFFF;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12);
    }

    .more {
        position: absolute;
        top: 69px;
        font-size: 12px;
        font-weight: 400;
        color: #FFFFFF;
        text-shadow: 0px 1px 6px rgba(0, 0, 0, 0.12);
        display: flex;
        align-items: center;
    }
}

.immu {
    background: linear-gradient(90deg, #48CF9A 0%, #22AF81 100%);
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    margin: 40px 0;
    padding: 24px 24px 0 24px;
    box-sizing: border-box;
}


.immuDesc {
    font-size: 14px;
    color: #FFFFFF;
    line-height: 20px;

}

.immuIcons {
    margin-top: 38px;
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding: 0 12px 20px 12px;
    box-sizing: border-box;

    .immuItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 17px;
    }
}

.testImmu {
    background: linear-gradient(90deg, #2AB0F6 0%, #337EFF 100%);
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    margin-bottom: 30px;
    padding: 24px 24px 0 24px;
    box-sizing: border-box;
}

.testIcons {
    margin-top: 25px;
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding-bottom: 20px;
    box-sizing: border-box;

    .testItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 17px;
        text-align: center;
    }
}

.testArrow {
    display: flex;
    align-items: center;
    margin-bottom: 11px;
    width: 8px;
}

.usualProblem {
    height: 25px;
    font-size: 18px;
    color: #000000;
    line-height: 25px;
    letter-spacing: 2px;
    margin-bottom: 20px
}


.detail {
    width: 87%;
    font-size: 14px;
    color: #808080;
    line-height: 20px;
    margin-top: 12px;
    text-align: justify;
}

.detailButton {
    font-size: 14px;
    color: #22AF81;
    line-height: 20px;
    letter-spacing: 1px;
    margin-top: 8px;
}

.gotoNext {
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    padding: 13px 0;
    text-align: center;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.van-collapse-item {
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    padding: 18px 20px;
    box-sizing: border-box;
    margin-bottom: 20px;

    ::v-deep .van-cell {
        padding: 0 !important;
        height: 20px;

        .van-cell__title {
            height: 20px;
            font-size: 14px;
            color: #000000;
            line-height: 20px;
            letter-spacing: 1px;
        }

        &::after {
            border: 0;
        }
    }
}

::v-deep .van-collapse-item__content {
    padding: 0;
}


::v-deep .van-collapse-item__title .van-cell__right-icon::before {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}

::v-deep .van-collapse-item__title--expanded .van-cell__right-icon::before {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.bottom {
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    background: #FFFFFF;
    box-shadow: 0px -1px 1px 0px rgba(0, 0, 0, 0.12);
    padding: 10px 24px 0 24px;
    box-sizing: border-box;

    .buy {
        margin-left: 20px;
        flex: 1;
        height: 40px;
        background: #1477FD;
        border-radius: 8px;
        text-align: center;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 20px;
        border: 0;
    }
}

.cuService {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 10px;
    font-weight: 400;
    color: #333333;
    line-height: 14px;
}
</style>
