export default {
    /**** 公共 ****/
    0: '操作成功',
    1: '操作失败',
    2: '请求参数异常',
    10001: '登录令牌过期',
    10002: '刷新令牌过期',
    10003: '用户名或密码错误',
    10004: '用户账户异常',
    10005: '用户未授权',
    10006: '登录令牌验证失败',
    10007: '登录令牌被废除',
    10008: '请求参数缺失或者有误',
    10009: '账号被踢出',
    10010: '原密码有误',
    10011: '登录验证码错误',
    10012: '两次密码设置不一致',
    10013: '验证码已过期',
    10014: '微信登录已过期',
    10015: '微信登录异常',
    10016: '账号已存在',
    10017: '手机号已存在',
    10018: '禁止敏感词作为账号信息',
    10019: '账户不存在',
    10020: '账户信息不允许修改',
    10023: '验证码错误',
    10024:'验证码还在有效期,可继续使用'
}
