import { appid } from './config'

// 授权登录
export const login = (to) => {
    let { token } = to ? to.query : ''  //如果to存在，就返回路径参数，没有token就是undefined
    if (token != undefined && token != '') {
        localStorage.setItem('token', token)
    } else {
        let t = localStorage.getItem('token')
        if (t == '' || t == 'undefined' || t == null) {
            window.location.href = authorizeUrl()
        }
    }
}

export function authorizeUrl() {
    // const origin = window.location.origin
    const origin = 'https://kb-tseq.com'
    // const origin = 'http://192.168.54.112:8080'
    const myUrl = window.location.href
    const _appid = appid
    const redirect_uri = `${origin}/apis/patient/auth/callback?url=${encodeURIComponent(myUrl)}`
    const response_type = 'code'
    const scope = 'snsapi_userinfo'
    return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${_appid}&redirect_uri=${encodeURIComponent(redirect_uri)}&response_type=${response_type}&scope=${scope}#wechat_redirect`
}

