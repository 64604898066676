import Vue from 'vue';
import App from './App.vue';
import router from './router';
import "./assets/scss/font.css"
import "./static/css/global.css"

import Vant from 'vant';
import 'vant/lib/index.css';

import lazyImg from "@/components/lazyImg.vue"

// 放大预览
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'


Vue.use(Viewer)
Viewer.setDefaults({
    title: false,
    toolbar: false,
    navbar: false,
    button: false
})

Vue.use(Vant);
Vue.component('lazyImg', lazyImg)

new Vue({
    router,
    render: (h) => h(App),
}).$mount('#app');
