<template>
   <div>
      <div style="padding:20px 24px 40px 24px">
         <div class="globalTitle" style="color: #7C4DFF;margin-bottom: 16px;">T细胞与B细胞</div>
         <div class="globalText" style="margin: 20px 0;">
            T细胞与B细胞是适应性免疫的主角，在适应性免疫中T细胞会特异识别目标，结合上去并迅速增殖，以精准、快速的杀灭被感染或衰老、畸变的细胞。它还能指导B细胞产生抗体，抗体能与病原体表面的特异抗原结合，继而引导其他免疫细胞来杀灭病原体。当战斗结束时，其中一些T细胞和B细胞会留下来记住这种致病威胁的特征，当身体再次遭遇相同的目标时，它们可以更快、更有针对性地反应。
         </div>
         <lazyImg :src="imgs[0]" alt="" class="center imgTransition" style="width: 327px;"></lazyImg>
         <div class="globalText" style="margin: 20px 0;">由此可见，T细胞是适应性免疫应答的主要参与者，其功能的正常与否，是评估免疫力的重要指标。</div>
         <div class="globalTitle" style="color: #7C4DFF;margin-top:40px">T细胞的“眼睛”-T淋巴细胞受体 (TCR)</div>
         <div class="globalText" style="margin: 20px 0;">T细胞之所以能识别成千上万种威胁，关键是其表面的T淋巴细胞受体(TCR)。</div>
         <lazyImg :src="imgs[1]" alt="" class="center imgTransition" style="width: 327px;"></lazyImg>
         <div class="globalTitle" style="color: #7C4DFF;margin-top:40px">TCR的形成过程和不同种类的T淋巴细胞</div>
         <div class="globalText" style="margin: 20px 0;">TCR基因序列会随着细胞分化成熟而重新排列，从而产生10<sup>15</sup>~10<sup>18</sup>
            种不同的基因序列组合，这一过程称为V(D)J重排，它赋予了T淋巴细胞独特且唯一的基因标识，也就是T淋巴细胞的种类。V(D)J重排产生的种类繁多的T淋巴细胞，使人类的免疫系统能够监控和应对各种各样不同的致病威胁。
         </div>
         <lazyImg :src="imgs[2]" alt="" class="center imgTransition" style="width: 327px; margin-bottom: 20px;"></lazyImg>
         <lazyImg :src="imgs[3]" alt="" class="center imgTransition" style="width: 327px;"></lazyImg>
         <div class="globalTitle" style="color: #7C4DFF;margin-top:40px">免疫组库测序技术 - 对T淋巴细胞受体 (TCR) 进行定量检测</div>
         <div class="globalText" style="margin: 20px 0;">T淋巴细胞种类数以万计，通过对TCR的分析可进行精准识别。</div>
         <div class="globalText" style="margin: 20px 0;">
            要实现对种类为天文数字的T细胞进行检测，常规的免疫细胞T/B/NK分型(流式细胞术)是无法做到的。</div>
         <div class="globalText" style="margin: 20px 0;">
            康贝测(KB-Tseq)为解决海量数据获取与分析的问题，特自主开发了免疫组库测序技术。我们使用特异性的无偏引物体系，以T淋巴细胞受体为目标，采用多重PCR技术扩增TCR互补决定区(CDR3区域)，并对高通量测序获得的数据使用机器算法处理，最终完美解析出独属于您的免疫力评估结果。
         </div>
         <lazyImg :src="imgs[4]" alt="" class="center imgTransition" style="width: 327px; margin-bottom: 20px;"></lazyImg>
         <lazyImg :src="imgs[7]" alt="" class="center imgTransition" style="width: 30px; margin-bottom: 20px;"></lazyImg>
         <lazyImg :src="imgs[5]" alt="" class="center imgTransition" style="width: 327px; margin-bottom: 20px;"></lazyImg>
         <!-- <img src="../../images/ana/7.png" alt="" class="center" style="width: 327px; margin-bottom: 20px;"> -->
         <div class="globalTitle" style="color: #7C4DFF;margin:40px 0 27px 0">康贝测 (KB-Tseq) 评测流程</div>
         <lazyImg :src="imgs[6]" alt="" class="center" style="width: 327px;"></lazyImg>
      </div>
   </div>
</template>
<script>
export default {
   ame: 'anaysis',
   data() {
      return {
         imgs: [
            require('../../images/ana/1.png'),
            require('../../images/ana/2.png'),
            require('../../images/ana/3.png'),
            require('../../images/ana/4.png'),
            require('../../images/ana/5.png'),
            require('../../images/ana/6.png'),
            require('../../images/ana/8.png'),
            require('../../images/ana/arrow.png'),
         ]
      }
   },
   created() {
   },
   mounted() {
      // 页面置顶
      document.documentElement.scrollIntoView()
   },
   computed: {
   },
   methods: {
   },
}
</script>
<style lang="scss" scoped>
.title {
   margin-bottom: 16px;
   font-size: 18px;
   font-weight: 500;
   color: #7C4DFF;
   line-height: 25px;
   letter-spacing: 2px;
}

.word {
   margin: 20px 0;
   font-size: 14px;
   font-weight: 500;
   color: #333333;
   line-height: 22px;
   letter-spacing: 1px;
   text-align: justify;
}

.center {
   position: relative;
   left: 50%;
   transform: translateX(-50%);
}
</style>
