<template>
   <div style="padding-bottom:40px">
      <lazyImg :src="imgs[0]" class="imgTransition" alt=""></lazyImg>
      <div style="padding:0 24px;margin-top: 30px;">
         <div class="globalTitle" style="margin-bottom: 16px;color: #1477FD;">
            免疫力</div>
         <div class="globalText" style="margin-bottom: 30px;">
            <div>在日常生活中，我们常用免疫力来指代免疫功能的强弱，免疫功能，是机体识别和清除外来入侵抗原及体内突变或衰老细胞，并维持机体内环境稳定的功能的总称。</div>
            <div>其依靠免疫器官、免疫细胞、免疫分子发挥作用！</div>
         </div>
      </div>
      <lazyImg :src="imgs[1]" class="imgTransition" alt=""></lazyImg>
      <lazyImg :src="imgs[2]" class="bottomImg imgTransition" alt=""></lazyImg>
   </div>
</template>
<script>
export default {
   name: 'introduction',
   data() {
      return {
         imgs: [
            require('../../images/intro/banner.png'),
            require('../../images/intro/intro.png'),
            require('../../images/intro/bottom.png')
         ]
      }
   },
   created() {
   },
   mounted() {
      // 页面置顶
      document.documentElement.scrollIntoView()
   },
   computed: {
   },
   methods: {
   },
}
</script>
<style lang="scss" scoped>
.bottomImg {
   margin: 40px 24px 0px 24px;
}
</style>
