// -request.js
import axios from 'axios'
import { Toast } from 'vant';
import { login } from "../util/weiXinUtils"
import ResCode from './code'

//请求拦截器：在发请发之前，请求拦截器可以检测到，可以在请求发出去之前做一些事情
axios.interceptors.request.use(function (config) {
    // 设置token
    config.headers.Authorization = 'Bearer ' + localStorage.getItem('token');

    return config;
}, function (err) {
    console.log(err)
})

//响应拦截器
axios.interceptors.response.use(async (res) => {
    if (res.status != 200) {
        setTimeout(() => {
            Toast(ResCode[res.data.code])
        }, 100);
    } else if (res.data.code != undefined && res.data.code != 0) {
        // 令牌废除直接跳回登录页面
        if (res.data.code == 10001 || res.data.code == 10002 || res.data.code == 10006 || res.data.code == 10007) {
            // token过期
            localStorage.setItem('token', '')
            // 重新登录
            login()
        } else {
            setTimeout(() => {
                Toast(ResCode[res.data.code])
            }, 100);
        }
    } else {
        return res
    }
}, function (err) {
    console.log(err)
})

export default axios

